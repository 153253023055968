export const LocalesMap = new Map([
    ["ar", "اَلْعَرَبِيَّةُ"],
    ["en", "English"],
    ["es", "Español"],
    ["fr", "Français"],
    ["hi", "हिन्दी"],
    ["id", "Bahasa Indonesia"],
    ["it", "Italia"],
    ["ja", "日本"],
    ["ms", "Bahasa Melayu"],
    ["pt", "Português"],
    ["th", "ภาษาไทย"],
    ["tl", "Tagalog"],
    ["ur", "اُردُو"],
    ["vi", "Tiếng Việt"],
    ["zh-Hans", "汉语"],
]);
