import { logRequest, logResponse, logError } from "helpers/loggerData";
import { IProfile } from "../profile/getProfile";

export const updateCustomFields = async (
    token: string,
    body: {
        customFields: {
            [key in any]: string;
        },
    }
): Promise<IProfile | undefined> => {

    const logData = {
        desc: "Update custom fields",
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API}/client-api/profile/update-custom-fields`,
    };
    const { url, method } = logData;

    try {

        logRequest(logData, body);

        const response = await fetch(url,
            {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            },
        );

        const { ok: responseOk } = response;

        logResponse(logData, response);

        if ( responseOk ) {
            const json = await response.json();
            return json;
        }
    } catch (error: unknown) {
        console.error(error);
        logError(logData, error);
    }
};
