import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { getAmountWithCurrencySymbol } from "helpers/amount-symbol";
import { Typography } from "../typography/typography.component";
import { TypographyTypes } from "../typography/typography.types";
import { IData } from "lib/hooks/ib/referrals";
import { IconTypes } from "../icon/icon.types";
import { useModals } from "components/providers/modals/modals.provider";
import {
    StyledRecentItem,
    StyledRecentItemHead,
    StyledHeadTitle,
    StyledTextMuted,
    StyledButton,
    StyledButtonIcon,
    StyledRecentContent,
    StyledInfoItem,
    StyledInfoLine,
} from "../recent-item/recent-item.styled";
import {
    StyledVerifiedIcon,
    StyledVerifiedBox,
    StyledLevelIcon,
    StyledLevelBox,
    StyledLevelButton,
} from "./referrals-item.styled";

interface IProps {
    data: IData[];
    index?: number;
    currency: string;
}

interface IConfig {
    key: string;
    text: string;
    value: string | number;
    verified?: string;
}

export const ReferralsItem: React.FC<IProps> = ({ data, index, currency }) => {
    const [__] = useTranslation();
    const [ view, setView ] = useState<boolean>(false);
    const { openReferralsLevelModals } = useModals();

    const searchValue = (text: string) => {
        const value = data.filter((item) => item.key.includes(text))[0].value;
        if(value) {
            return value;
        } else {
            return "";
        }
    };

    const configHead = {
        firstName: searchValue("firstName"),
        lastName: searchValue("lastName"),
        commission: +searchValue("commission"),
        lots: +searchValue("totalTradedLots"),
    };

    const firstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const configContent: IConfig[] = [
        {
            key: "level",
            text: __("modals.partnership.referral-level"),
            value: firstLetter(searchValue("level")),
        },
        {
            key: "state",
            text: __("modals.partnership.state"),
            value: +searchValue("isVerified") ? __("modals.partnership.verified") : __("modals.partnership.unverified"),
            verified: searchValue("isVerified"),
        },
        {
            key: "balance",
            text: __("modals.partnership.balance"),
            value: getAmountWithCurrencySymbol(currency, +searchValue("balance")),
        },
        {
            key: "commission",
            text: __("modals.partnership.commission"),
            value: getAmountWithCurrencySymbol(currency, +searchValue("commission")),
        },
        {
            key: "deposits",
            text: __("modals.partnership.deposits"),
            value: getAmountWithCurrencySymbol(currency, +searchValue("deposits")),
        },
        {
            key: "total lots",
            text: __("modals.partnership.total-traded-lots"),
            value: +searchValue("totalTradedLots"),
        },
        {
            key: "closed",
            text: __("modals.partnership.p&l-closed"),
            value: getAmountWithCurrencySymbol(currency, +searchValue("referrals_pl_closed")),
        },
    ];

    return (
        <StyledRecentItem $animation={index}>
            <StyledRecentItemHead>
                <div>
                    <StyledHeadTitle>
                        <Typography variant={TypographyTypes.LARGE}>
                            {configHead.firstName === "User-Placeholder" || configHead.lastName === "User-Placeholder"
                            ?
                                __("modals.partnership.unverified-user")
                            :
                                `${configHead.firstName} ${configHead.lastName}`
                            }
                        </Typography>
                        <Typography variant={TypographyTypes.LARGE}>
                            {getAmountWithCurrencySymbol(currency, configHead.commission)}
                        </Typography>
                    </StyledHeadTitle>
                    <StyledTextMuted variant={TypographyTypes.SMALL}>
                        { __("modals.partnership.lots-amount", { amount: configHead?.lots.toFixed(4) || 0 }) }
                    </StyledTextMuted>
                </div>
                <StyledButton view={view} onClick={() => setView((prev) => !prev)}>
                    <StyledButtonIcon icon={IconTypes.ARROW}/>
                </StyledButton>
            </StyledRecentItemHead>
            <StyledRecentContent view={view}>
                {configContent.map((item) => (
                    <StyledInfoItem key={item.key}>
                        {item.key === "level" ?
                            <StyledLevelBox>
                                <StyledTextMuted variant={TypographyTypes.BASE}>{item.text}</StyledTextMuted>
                                <StyledLevelButton onClick={openReferralsLevelModals}>
                                    <StyledLevelIcon icon={IconTypes.INFO} />
                                </StyledLevelButton>
                            </StyledLevelBox>
                            :
                            <StyledTextMuted variant={TypographyTypes.BASE}>{item.text}</StyledTextMuted>
                        }
                        <StyledInfoLine />
                        {item.verified ?
                            <StyledVerifiedBox>
                                <StyledVerifiedIcon
                                    icon={IconTypes.DOT_VERIFIED}
                                    verified={+item.verified === 1}
                                />
                                <Typography variant={TypographyTypes.BASE}>{item.value}</Typography>
                            </StyledVerifiedBox>
                            :
                            <Typography variant={TypographyTypes.BASE}>{item.value}</Typography>
                        }
                    </StyledInfoItem>
                ))}
            </StyledRecentContent>
        </StyledRecentItem>
    );
};
