export enum MixpanelTypes {
    REGISTER = "register",
    LOGIN = "login",
    RESET = "reset",
    PROFILE = "profile",
    EMAIL_VERIFICATION = "email-verification",
    QUEST_START = "quest-start",
    QUEST_SECOND = "quest-two",
    QUEST_THREE = "quest-three",
    QUEST_FINISH = "quest-finish",
    SUMSUB = "sumsub",
    EMAIL_VERIFICATION_SUCCESS = "email-success",
    EMAIL_VERIFICATION_ERROR = "email-error",
    PROOF_OF_IDENTITY = "proof-identity",
    PROOF_OF_RESIDENCE = "proof-residence",
    PROOF_OF_IDENTITY_MODAL = "proof-identity-modal",
    PROOF_OF_RESIDENCE_MODAL = "proof-residence-modal",
    MANUAL_EMAIL_VERTIFICATION = "manual-email-verification",
}
