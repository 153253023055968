import React, { useState, useEffect } from "react";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { Typography } from "components/atoms/typography/typography.component";
import { IconTypes } from "components/atoms/icon/icon.types";
import {
    StyledNotification,
    StyledNotificationClose,
    StyledCloseIcon,
    StyledAuthIcon,
} from "./notification-top.styled";

export interface IProps {
    closeModal: () => void;
    text: string;
    notIcon: boolean;
}

export const NotificationTopModals: React.FC<IProps> = ({ closeModal, text, notIcon }) => {
    const [active, setActive] = useState<boolean>(Boolean(text));

    useEffect(() => {
        setActive(true);
        const setActiveTimeOut = setTimeout(() => {
            setActive(false);
        }, 3500);
        const closeModalTimeOut = setTimeout(() => {
            if (closeModal && typeof closeModal === "function") {
                closeModal();
            }
        }, 4000);
        return () => {
            clearTimeout(setActiveTimeOut);
            clearTimeout(closeModalTimeOut);
        };
    }, [closeModal]);

    return (
        <StyledNotification
            $active={active}
            isOverlay={false}
        >
            {!notIcon && <StyledAuthIcon icon={IconTypes.CLOSE_AUTH} />}
            <Typography variant={TypographyTypes.BASE}>{text}</Typography>
            <StyledNotificationClose onClick={closeModal} data-testid="close-button">
                <StyledCloseIcon icon={IconTypes.CLOSE} />
            </StyledNotificationClose>
        </StyledNotification>
    );
};
